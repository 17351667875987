@media (max-width:1600px) {}

@media (max-width:1399px) {

        /* INDEX RESPONSIVE */

    li.brd:after {
        width: 1050px;
    }
    .section-lists .container {
        padding: 0;
    }
    .container.cc-coin {
        padding: 0px;
    }
    input[type=submit] {
        width: 40px;
        height: 40px;
    }
    .input-sec input#fname {
        font-size: 20px;
    }
    ul.coins-list li {
        padding: 6px 22px 5px 25px;
    }
    li.bg-cross-btn img {
        right: 5%;
    }
    .page-logo img {
        width: 180px;
    }
    

}

@media (max-width:1169px) {

    /* INDEX RESPONSIVE */

    li.brd:after {
        width: 900px;
    }

    .page-logo img {
        width: 150px;
    }

    .banner-btn a {
        font-size: 14px;
    }

    li.bg-cross-btn img {
        right: 5%;
    }

    ul.coins-list li {
        padding: 6px 40px 5px 8px;
    }

    .input-sec input#fname {
        font-size: 18px;
    }

    .container.cc-coin {
        padding: 0;
    }
    section.section-lists.pd-40 {
        padding: 30px 0;
    }

    .section-lists .container {
        padding: 0;
    }
    ul.comment-list li {
        padding: 15px 15px;
    }

    input[type=submit] {
        width: 45px;
        height: 40px;
    }
}

@media (max-width:1024px) {

    /* INDEX RESPONSIVE */

    li.brd:after {
        width: 870px;
    }

    .banner-btn a {
        padding: 10px 30px;
        font-size: 12px;
    }

    .page-logo img {
        width: 100px;
    }

    .section-lists .container {
        padding: 0;
    }

    .input-sec input#fname {
        font-size: 12px;
    }

    .container.cc-coin {
        padding: 0;
    }

    li.bg-coin-cc {
        font-size: 18px;
    }

    li.bg-coin-br {
        font-size: 13px;
    }

    ul.coins-list li {
        padding: 6px 50px 5px 8px;
    }

    li.bg-cross-btn img {
        right: 5%;
    }

    section.section-lists.pd-40 {
        padding: 20px 0;
    }

    input[type=submit] {
        width: 30px;
        height: 30px;
    }
    ul.comment-list li {
        padding: 15px 10px;
    }
    .input-sec form {
        justify-content: space-between;
    }

}

@media (max-width:991px) {

    /* INDEX RESPONSIVE */

    li.brd:after {
        width: 650px;
    }

    .banner-btn a {
        padding: 10px 30px;
        font-size: 12px;
    }

    .page-logo img {
        width: 100px;
    }

    .section-lists .container {
        padding: 0;
    }

    .input-sec input#fname {
        font-size: 20px;
    }

    .container.cc-coin {
        padding: 0;
    }

    li.bg-coin-cc {
        font-size: 18px;
    }

    li.bg-coin-br {
        font-size: 13px;
    }

    ul.coins-list li {
        padding: 6px 20px 5px 8px;
    }

    li.bg-cross-btn img {
        right: 5%;
    }

    section.section-lists.pd-40 {
        padding: 20px 0;
    }

    input[type=submit] {
        width: 50px;
        height: 50px;
    }
    .col-3.comment-area {
        width: 100%;
        margin-top: 10px;
    }
    .input-stream input#fname {
        font-size: 18px;
    }
    .input-stream form {
        justify-content: space-between;
    }
    .col-5.blond-image {
        width: 100%;
        height: 600px;
    }
    section.send-main .container.blond .row .col-4.comment-area {
        margin-top: 10px;
        margin-left: 0;
        width: 100%;
    }
    section.send-main .container.blond .row {
        flex-direction: column;
    }
    .col-8.blond-image {
        width: 100%;
    }
    .input-sec form {
        justify-content: space-between;
    }

}

@media (max-width:767px) {

    /* INDEX RESPONSIVE */

    .banner-btn a {
        padding: 10px 20px;
        font-size: 10px;
    }

    .page-logo img {
        width: 80px;
        top: 20%;
        left: 45%;
    }

    .col-8.blond-image {
        width: 100%;

    }

    section.send-main .container.blond .row .col-4.comment-area {
        margin: 10px 0px;
        width: 100%;
        height: auto;
    }

    section.send-main .container.blond .row {
        flex-direction: column;
    }

    li.brd:after {
        width: 420px;
    }

    .section-lists .container {
        height: 240px;
    }

    .container.cc-coin {
        height: auto;
        justify-content: center;
    }

    ul.coins-list {
        flex-direction: column;
    }

    li.bg-coin-br {
        margin: 8px 8px;
    }

    .iner-cont {
        flex-direction: column;
        padding: 100px 20px;
    }

    ul.bb-ll-list {
        flex-direction: column;
        padding: 0;
    }

    .blond-image .live-kk {
        margin-bottom: 10px;
        justify-content: center;
    }

    ul.bb-ll-list li {
        margin: 5px 5px;
        justify-content: center;
    }

    .bg-cross-btn {
        position: relative;
    }

    li.bg-cross-btn img {
        right: -17%;
        bottom: -90px;
    }

    .bottom-section2 {
        display: flex;
        padding: 30px 20px;
        flex-direction: row;
    }

    .input-sec input#fname {
        font-size: 18px;
    }

    .container {
        padding-right: 0;
        padding-left: 0;
    }

    ul.ft-list {
        padding: 0 20px;
    }

    section.section-lists.pd-40 {
        padding: 20px 0;
    }

    .col-5.blond-image {
        width: 100%;
    }

    .iner-cont.bottom-section:before {
        height: 50%;
    }
    .blond-image .live-call {
        bottom: -100px;
    }
    .sound {
        justify-content: center;
        bottom: -90px;
    }
    .head-comment h2 {
        text-align: center;
    }
    .col-3.comment-area {
        width: 100%;
    }
    .input-sec form {
        justify-content: space-between;
    }
    ul.ft-list li {
        font-size: 20px;
    }
}

@media (max-width:479px) {

    /* INDEX RESPONSIVE */

    .banner-btn a {
        padding: 8px 10px;
        font-size: 8px;
    }

    .page-logo img {
        width: 40px;
    }

    .col-8.blond-image {
        width: 100%;

    }

    section.send-main .container.blond .row .col-4.comment-area {
        margin: 10px 0px;
        width: 100%;
        height: auto;
    }

    section.send-main .container.blond .row {
        flex-direction: column;
    }

    li.brd:after {
        width: 280px;
    }

    .section-lists .container {
        height: 240px;
    }

    .container.cc-coin {
        height: auto;
        justify-content: center;
    }

    ul.coins-list {
        flex-direction: column;
    }

    li.bg-coin-br {
        margin: 8px 8px;
    }

    .iner-cont {
        flex-direction: column;
        padding: 70px 20px;
    }

    ul.bb-ll-list {
        flex-direction: column;
        padding: 0;
    }

    .blond-image .live-kk {
        margin-bottom: 10px;
        justify-content: center;
    }

    ul.bb-ll-list li {
        margin: 5px 5px;
        justify-content: center;
    }

    .bg-cross-btn {
        position: relative;
    }

    li.bg-cross-btn img {
        right: -17%;
        bottom: -90px;
    }

    .bottom-section2 {
        display: flex;
        padding: 30px 20px;
        flex-direction: row;
    }

    .input-sec input#fname {
        font-size: 18px;
    }

    .container {
        padding-right: 0;
        padding-left: 0;
    }

    ul.ft-list {
        padding: 0 20px;
    }

    section.section-lists.pd-40 {
        padding: 20px 0;
    }

    .col-5.blond-image {
        width: 100%;
    }

    .iner-cont.bottom-section:before {
        height: 100%;
    }
    .blond-image .live-call {
        bottom: -65px;
    }
    .sound {
        justify-content: center;
        bottom: -50px;
    }
    .head-comment h2 {
        text-align: center;
    }
    .col-3.comment-area {
        width: 100%;
    }
    ul.ft-list li {
        font-size: 20px;
    }

}