.container{
    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1300px;
}

/* HEADER SECTION */

.banner {
    position: relative;
}

.page-logo img {
    position: absolute;
    top: 20%;
    left: 40%;
}

.banner-btn{
    text-decoration: none;
    background: #f21366;
    color: #fff;
    padding: 15px 40px;
    border-radius: 50px;
    position: absolute;
    right: 5%;
    bottom: 15%;
}

.banner-inner img {
    width: 100%;
}

ul.ft-list.pink li {
    text-align: right;
    color: #f21366;
}
 
.section-lists .container {
    background-image: url(../images/s1-bg.png);
    /* padding: 0; */
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 248px;
     
}

.section-lists .container .row {
    position: relative;
}

.col-6 {
    display: flex;
    justify-content: space-between;
}

ul.ft-list li {
    font-size: 26px;
    list-style: none;
    line-height: 60px;
}


li.brd:after {
    border-bottom: 1px solid #ddd7d7;
    width: 1200px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 22222;
}

li.brd {
    position: relative;
}

ul.ft-list {
    padding: 0 50px;
}

section.section-lists.pd-40 {
    padding: 40px 0;
}

/*-----------------------section-One-css-close-------------------------------*/

section.send-coins {
    padding: 10px 0;
}

.container.cc-coin {
    background: #15304c;
    height: 90px;
    display: flex;
    align-items: center;
    border-radius: 15px;
     
}

ul.coins-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 0;
}

li.bg-coin-cc img {
    background: #f21366;
    padding: 10px;
    border-radius: 45px;
    width: 25px;
    margin-right: 7px;
}

li.bg-coin-cc {
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
}

ul.coins-list li {
    padding: 6px 22px 5px 8px;
    display: flex;
    align-items: center;
    border-radius: 7px;
}


li.bg-coin-br {
    background: #465665;
    margin: 0 8px;
    border: 1px solid #f21366;
    color: #fff;
    font-size: 18px;
    padding: 20px;
}

li.bg-coin-br img {
    margin-right: 7px;
    width: 20px;
}

 /* .pd-40 {
     padding: 40px 0;
 } */

li.bg-cross-btn img {
    width: 45px;
    position: absolute;
    right: 17%;
}

/* .OT_fit-mode-cover .OT_video-element {
    object-fit: contain !important;
} */
/*-----------------------second-section-css-close----------------------------*/

.col-8.blond-image {
    width: 100%;
    background-color: #c1c1c1; 
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

 

.live-kk {
     display: flex;
     flex-direction: row;
     align-items: center;
     color: #fff;
     font-size: 22px;
     background: #f21366;
     padding: 10px 20px;
     border-radius: 10px;
     position: relative;
     z-index: 2;
     cursor: pointer;
}

.live-kk span
{
    color: #fff;
    font-size: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

 /* .blond-image {
     display: flex;
    flex-direction: row;
   align-items: flex-start;
    justify-content: space-between;
 } */

.live-kk img {
    padding-right: 6px;
    width: 30px;
}

.sound {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    z-index: 2;
}

ul.bb-ll-list li {
    list-style: none;
    display: flex;
    background: #000000ad;
    flex-direction: row;
    align-items: center;
    margin: 0 5px;
    padding: 10px;
    border: 1px solid #ffffff78;
    border-radius: 10px;
}

ul.bb-ll-list {
    display: flex;
    margin: 0;
}

 

li.view img {
    width: 30px;
    margin-right: 6px;
}

li.view span {
    color: #fff;
    font-size: 22px;
}

section.send-main .container.blond {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.iner-cont {
    position:absolute;
}
.blond-image{
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center;     */
    height: 100vh;    
    width: 100%;
    background-color: red;
}

.user-friend img{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    font-size: 22px;
    background: #f21366;
    padding: 10px;
    border-radius: 10px;
}

.bottom-section2{
background:#15304c;
}


.iner-cont.bottom-section {
    border-radius: 10px;
}

.iner-cont.bottom-section:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0.2;
    z-index: 1;
}

section.send-main .container.blond .row {
    display: flex;
}

section.send-main .container.blond .row .col-4.comment-area {
    margin: 0 10px;
    width: 30%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
}

.con-main-are {
    background: #15304c;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    height: 100vh;
}

.input-sec {
    background: #15304c;
    padding: 20px;
    border-radius: 10px;
}

.head-comment h2 {
    color: #fff;
    border-bottom: 1px solid #958b8b42;
    padding-bottom: 12px;
    margin-top: 0px;
}

ul.comment-list {
    margin: 0;
    padding: 0;
    height: 600px;
    overflow-y: scroll;
}

ul.comment-list li {
    list-style: none;
    padding: 0;
    background: #0f2235;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 10px;
}

ul.comment-list li h3 {
    margin: 0;
}

ul.comment-list li p {
    font-size: 14px;
    margin-top: 5px;
}

.live-friend img {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    font-size: 22px;
    background: #6d6867;
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: contain;
}

 

 
input[type=submit] {
    background: url(../images/submit-1.png);
    border: 0;
    display: block;
    width: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
}



/*------------index-css-close---------------------*/


.col-5.blond-image{
     width: 50%;
    background-image: url(../images/blonde.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
}

.col-4.comment-area {
    margin: 0 10px;
    width: 25%;
    height: 675px;
}

.col-3.comment-area {
    width: 25%;
}

span.active-ttl img {
    width: 40px;
    margin-right: 10px;
}
 

ul.comment-list.active-listy li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

ul.comment-list.active-listy li span {
    display: flex;
    align-items: center;
}


.users{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: '#465665';
    margin-top: 10px;
}


.OTPublisherContainer {
    height: 100vh !important
}
.OTSubscriberContainer {
    height: 100vh !important
}
.custom_buttons{
    background-color: #f21366 !important;
    word-wrap: break-word;
    margin: 0.4rem;
    border-width: 0px;
    font-size: 0.9rem;
}
.custom_buttons:focus{
    background-color: #f21366 !important;
}
.customIcon{
height: 0.7rem;
background-size: contain;
padding-right: 0.3rem;
}

.side-buttons {
    position: absolute;
    justify-content: space-between;
    z-index: 999;
    top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  
  .opener {
    transition: all 0.5s ease;
    background-color: rgba(22,48,76,0.7);
    padding: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    border-radius: 50px 0px 0px 50px;
    position: relative; 
    left: calc(100% - 50px);
  }

  /* @media (min-width: 768px) {
    .opener{
        left: calc(100% - 60px);
    }
  } */
  
  .opener.active {
    left: 20px;
  }
  
  .side_image {
    width: 1.5rem;
    background-size: contain;
  }
  .drawer_inActive{
    position: absolute;
    z-index: 9999;
    left: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(22,48,76,0.95);
    overflow: hidden;
    transition: left 0.5s ease;
  }
  .drawer_inActive.drawer_active{
    left: 15%;
  }
  .chat_container{
    max-height: 250px;
    width: 100%;
    /* background-color:rgba(0, 0, 0, 0.1); */
    scrollbar-width: none;
  }
  .coin_btn {
    font-size: 2.5vw; /* Adjust this value as needed */
    border: 1px solid #f21366;
    background-color: #ffdc00;
    border-radius: 10px;
    margin-left: 1vw; /* Dynamic left margin */
    margin-right: 1vw; /* Dynamic right margin */
    flex: 1;
  }
  
  @media (max-width: 580px) {
    .coin_btn {
      font-size: 3.2vw; /* Adjust font size for small screens */
      margin-left: 0.5vw; /* Larger left margin for small screens */
      margin-right: 0.5vw; /* Larger right margin for small screens */
      padding: 2px;
    }
  }
  
  
  /* Specific rule for the first item */
  .coin_btn:first-child {
    margin-left: 1rem;
  }
  .coin_btn:last-child {
    margin-right: 1rem;
  }
  


   
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
  
  .slide-in {
    animation: slideIn 0.5s forwards;
  }
  
  .slide-out {
    animation: slideOut 0.5s forwards;
  }

  /* popup */
  @keyframes popupSlideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes popupSlideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
  
  .popupSlide-in {
    animation: slideIn 0.5s forwards;
  }
  
  .popupSlide-out {
    animation: slideOut 0.5s forwards;
  }
  

/* .OT_fit-mode-contain .OT_video-element{
    object-fit: cover !important;
} */
  